<template>
  <ion-card class="world-card-small d-flex">
    <router-link :to="goToWorldLink" class="clickable">
      <img loading="lazy" v-image :src="getThumbnail(world.cropped_profile_img || world.cover_image)" />
    </router-link>

    <div class="flex-grow-1 world-info" :class="[isMobSmallScreen ? 'p-1 pt-2' : 'p-2']">
      <div class="world-details">
        <div class="name ml-2 d-flex flex-row">
          <router-link class="routerlink clickable" :to="goToWorldLink">
            {{ world.name }}
          </router-link>
          <ion-badge id="click-trigger" color="danger" class="count">
            {{ worldUnreadCount > 0 ? worldUnreadCount : null }}
          </ion-badge>
          <!-- <ion-popover trigger="click-trigger"> {{ worldUnreadCount }} Unread Message in {{ world.name }} </ion-popover> -->
        </div>
        <div class="d-flex description ml-2 mt-2 flex-row">{{ worldDescriptionTruncated }}</div>
      </div>
      <div class="actions">
        <div class="actions-inner">
          <Reaction
            type="world"
            :reactions="reactions"
            :user-reaction="userReaction"
            :right-indent-popup="true"
            @changed="(reaction) => reactionChanged(reaction.key, world, reaction.isInstant)"
          >
            <ion-button color="transparent" class="inline-button icon-button clickable d-file">
              <div
                class="d-flex align-items-center justify-content-center"
                :class="{ 'text-secondary': !!userReaction && userReaction.reaction }"
              >
                <i
                  class="ti-thumb-up text-primary mr-1"
                  :class="{ 'text-secondary': !!userReaction && userReaction.reaction }"
                />
                <span class="reaction-count">{{ reactions?.total_count || 0 }}</span>
              </div>
            </ion-button>
          </Reaction>

          <ion-button color="transparent" class="inline-button icon-button ml-2">
            <div class="d-flex align-items-center justify-content-center">
              <i class="ti-user text-primary mr-1 user-icon" />
              <span color=" #3dc2ff" class="reaction-count text-primary">{{ memberCount || 0 }}</span>
            </div>
          </ion-button>
        </div>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WorldsInfo } from '@/shared/types/static-types';
import { truncateEasy, stripHtmlTags } from '@/shared/utils/string';
import store from '@/shared/store';
import { resizeUpload } from '@/shared/utils/upload';
import Reaction from '@/shared/components/Reaction/index.vue';

@Options({
  name: 'WorldCardSmall',
  components: { Reaction },
})
export default class WorldCardSmall extends Vue {
  @Prop({ default: () => ({}) }) world!: WorldsInfo;
  @Prop({ default: () => ({}) }) length!: any;
  @Prop({ default: 0 }) memberCount!: number;
  @Prop({ default: 0 }) worldUnreadCount!: number;

  resizeUpload = resizeUpload;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get worldDescriptionTruncated() {
    return truncateEasy(stripHtmlTags(this.world.description || ''), 200);
  }

  public get reactions() {
    const { reaction_counts } = this.world || {};
    return reaction_counts;
  }

  public get userReaction() {
    if (this.world.user_reaction) {
      return this.world.user_reaction;
    }
  }

  public async reactionChanged(reaction: string, wrld: any, isInstant = false) {
    const reactionResp = await store.dispatch('WorldsModule/react', { reaction, world: wrld, isInstant });
    this.$emit('afterReaction', reactionResp);
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 500;
  }

  public getThumbnail(url?: string) {
    if (!url) return '/world-placeholder.png';
    return resizeUpload(url, '250x250');
  }

  public get goToWorldLink() {
    const isDraft = this.world && !this.world.slug;
    const name = isDraft ? 'draft-story-world' : 'world-details';
    const params = isDraft ? { id: this.world.id } : { slug: this.world.slug };
    return { name, params };
  }
}
</script>

<style lang="sass" scoped>
.user-icon
  font-size: 16px
.actions
  position: absolute
  bottom: 1rem
  right: 1rem
.actions-inner
  width: 100px
  display: flex
  .inline-button
    &:not(:first-of-type)
      margin: 0 5px
.routerlink
  color: #214163
.world-info
  width: calc(100% - 93px)
.dark
  .world-card-small
    background-color: #1e1e1e
.world-card-small
  border-radius: 10px
  height: 150px
  background-color: white
  img
    min-width: 150px !important
    width: 150px !important
    min-height: 150px !important
    height: 150px !important
    background-color: #fff
    object-fit: cover
    border-top-left-radius: 10px
    border-bottom-left-radius: 10px
  .name
    font-size: 20px
    font-weight: bold
    overflow-y: hidden
    overflow-x: hidden
  .description
    font-size: 14px
    flex-grow: 1
    overflow: hidden
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
  .reaction-count
    font-size: 18px
    font-weight: bold
  .world-details
    height: 100px !important
    max-height: 100px !important
    overflow: hidden
@media(max-width: 500px)
  .world-details
    height: 100px !important
    max-height: 100px !important
    overflow: hidden
  .actions
    bottom: 0.3rem
    right: 0.25rem
  .name
    font-size: 16px !important
  .description
    font-size: 14px !important
  .world-card-small
    height: 150px
    img
      min-width: 150px !important
      width: 150px !important
      min-height: 150px !important
      height: 150px !important
      background-color: #fff
      object-fit: cover
      border-top-left-radius: 10px
      border-bottom-left-radius: 10px

@media(max-width: 800px)
  .description
    height: 50px !important
    max-height: 50px !important
    overflow: hidden
  .world-card-small
    height: 150px
    img
      min-width: 150px !important
      width: 150px !important
      min-height: 150px !important
      height: 150px !important
      background-color: #fff
      object-fit: cover
      border-top-left-radius: 10px
      border-bottom-left-radius: 10px
</style>
